<template>
  <div
    class="relative"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <a
      v-if="allowDelete && visible"
      class="cursor-pointer"
      @click="onRemove"
    >
      <a-icon
        name="times-circle"
        type="fas"
        class="absolute right-0 -mt-2"
      />
    </a>
    <img
      v-tooltip.bottom="help"
      :src="source"
      class="mr-1 h-20 border border-gray-200 border-b-0 cursor-pointer hover:shadow-lg"
      alt=""
      @click="onClick"
    >
    <span
      v-if="showPrimary && primary"
      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
    >
      Primary
    </span>
  </div>
</template>

<script>
import { get } from '@/utils/lodash';
import { resize } from '@/utils/resize';
export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    showPrimary: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'delete'],
  data: () => ({
    visible: false
  }),
  computed: {
    source() {
      return resize(this.image.image.path, 300, 300);
    },
    primary() {
      return get(this.image, 'primary');
    },
    help() {
      return this.showPrimary && !this.primary
        ? 'Click to mark as Primary'
        : '';
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.image);
    },
    onRemove() {
      this.$emit('delete', this.image);
    },
    onMouseEnter() {
      this.visible = true;
    },
    onMouseLeave() {
      this.visible = false;
    }
  }
};
</script>