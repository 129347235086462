<template>
  <div>
    <default-card
      v-for="video in videos"
      :key="video.id"
      :padding="false"
    >
      <embed-video :row="video" />
    </default-card>
  </div>
</template>

<script>
import EmbedVideo from '@/components/ui/embed/video.vue';
import DefaultCard from '@/components/exhibitors/cards/default.vue';
export default {
  components: {
    EmbedVideo,
    DefaultCard
  },
  props: {
    videos: {
      type: Array,
      required: true
    }
  }
};
</script>