<template>
  <img
    v-if="image.path"
    :src="source"
    :data-id="image.path"
    class="h-24 w-24 rounded-sm ring-4 ring-white sm:h-32 sm:w-32"
  >
</template>

<script>
import { resize } from '@/utils/resize';
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    source() {
      return resize(this.image.path, 500, 500, 'center', 'contain');
    }
  }
};
</script>