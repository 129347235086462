<template>
  <img
    :src="source" 
    class="h-40 w-full object-cover lg:h-80 rounded rounded-b-none"
  >
</template>

<script>
import { resize } from '@/utils/resize';
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  computed: {
    source() {
      return resize(this.image.path, 1200, 300, 'top', 'cover');
    }
  }
};
</script>