<template>
  <default-card
    title="Products"
    :padding="false"
  >
    <div
      class="grid grid-cols-1 lg:grid-cols-3"
    >
      <product-row
        v-for="item in products"
        :key="item.id"
        :product="item"
        class="border border-gray-200 group hover:shadow-xl cursor-pointer"
        @click="onProductClick"
      />
    </div>
  </default-card>
  <product-modal
    v-if="hasProduct"
    :product="product"
    @close="onCloseModal"
  />
</template>

<script>
import DefaultCard from '@/components/exhibitors/cards/default.vue';
import ProductModal from '@/components/exhibitors/ui/product/modal.vue';
import { isEmpty, find } from '@/utils/lodash';
import ProductRow from './row.vue';
export default {
  components: {
    ProductRow,
    DefaultCard,
    ProductModal
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    product: {}
  }),
  computed: {
    hasProduct() {
      return !isEmpty(this.product);
    }
  },
  watch: {
    ['$route.hash']: {
      immediate: true,
      handler: 'onHashChange'
    }
  },
  methods: {
    onProductClick(product) {
      this.product = product;
    },
    onCloseModal() {
      this.product = {};
    },
    onHashChange() {
      if (!isEmpty(this.$route.hash)) {
        this.product = find(
          this.products,
          product => product.id == this.$route.hash.replace('#', '')
        );
      } else {
        this.onCloseModal();
      }
    }
  }
};
</script>