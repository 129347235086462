<template>
  <div class="w-full flex items-center justify-between p-4 space-x-6">
    <contact-avatar :contact="contact" />
    <div class="flex-1 truncate">
      <div class="flex items-center space-x-3">
        <h3 class="text-gray-900 text-sm font-medium truncate">
          {{ contact.name }}
        </h3>
      </div>
      <p
        v-if="contact.title"
        class="mt-1 text-gray-500 text-sm truncate"
      >
        {{ contact.title }}
      </p>
    </div>
    <a
      class="py-2 px-4 rounded-md text-sm bg-primary text-white"
      :href="link"
      target="_blank"
    >
      <a-icon
        name="envelope"
        size="lg"
        class="mr-2"
      />
      Message
    </a>
  </div>
</template>

<script>
import ContactAvatar from './avatar.vue';
export default {
  components: {
    ContactAvatar
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return `mailto:${this.contact.email}`;
    }
  }
};
</script>