<template>
  <a-alert :type="alertType">
    <div class="flex justify-between">
      <div v-if="live">
        Virtual Booth Is Live!
      </div>
      <div v-else>
        Booth is Currently hidden, Toggle the switch when you are ready to go live.
      </div>
      <div class="flex justify-around">
        <div class="mr-1">
          {{ live ? 'Live' : 'Hidden' }}
        </div>
        <a-switch
          v-model="live"
          :loading="loading"
          :disabled="loading"
          variant="success"
        />
      </div>
    </div>
  </a-alert>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions } from 'vuex';
export default {
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    live: {
      get() {
        return this.exhibitor.live;
      },
      set(value) {
        this.onChange(value);
      }
    },
    alertType() {
      return this.live ? 'success' : 'error';
    }
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['update']),
    async onChange(value) {
      this.loading = true;
      try {
        await this.update({
          exhibitor: this.exhibitor.slug,
          form: { is_live: value }
        });
      } catch (e) {
        this.$notify('error', error(e));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>