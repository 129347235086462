<template>
  <a-card
    :shadow="false"
    class="border border-gray-200 shadow-none"
  >
    <template
      v-if="hasHeader"
      #header
    >
      <div class="bg-gray-200 p-2 uppercase">
        <slot name="header">
          {{ title }}
        </slot>
      </div>
    </template>
    <div :class="[paddingClass]">
      <slot />
    </div>
  </a-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    padding: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    hasHeader() {
      return !!this.$slots['header'] || this.title;
    },
    paddingClass() {
      if (typeof this.padding === 'boolean' && this.padding) {
        return 'p-2';
      }

      return this.padding;
    }
  }
};
</script>