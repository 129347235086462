<template>
  <div
    class="flex flex-col xl:flex-row"
  >
    <div class="w-full xl:w-8/12 md:p-2 space-y-6">
      <div>
        <booth-live-toggle
          v-if="canManageBooth"
          :exhibitor="exhibitor"
        />
        <booth-manage-alert v-if="canManageBooth" />
        <div v-if="hasBannerImage">
          <banner-image
            :image="bannerImage"
          />
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 bg-white border border-gray-200 border-t-0 rounded-md">
          <div
            class="sm:flex sm:items-end sm:space-x-5"
            :class="{'-mt-12 sm:-mt-16': hasBannerImage}"
          >
            <div class="flex">
              <logo-image
                v-if="hasLogoImage"
                :image="logoImage"
              />
            </div>
            <div class="lg:mb-2 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div class="block min-w-0 flex-1 py-2 mb-2 lg:py-0 lg:mb-0">
                <h1 class="text-3xl font-bold text-gray-900 truncate">
                  {{ title }}
                </h1>
              </div>
              <div class="flex flex-row justify-stretch space-x-4">
                <social-icons :row="exhibitor" />
                <a
                  v-if="website"
                  :href="website"
                  target="_blank"
                  class="-mt-1 px-2 py-1 text-sm bg-white border border-gray-300 rounded-lg hover:bg-primary hover:text-white"
                >
                  <a-icon
                    name="external-link"
                    type="fas"
                  />
                  Website
                </a>
              </div>
            </div>
          </div>
          <div class="mt-2 leading-7 text-gray-500">
            {{ description }}
          </div>
        </div>
      </div>
      <products-card
        v-if="hasProducts"
        :products="products"
      />
      <videos-card
        v-if="hasVideos"
        :videos="videos"
        class="grid grid-cols-1 lg:grid-cols-2 gap-2"
      />
    </div>
    <div class="w-full xl:w-4/12 md:p-2 space-y-6">
      <contacts-card
        v-if="hasContacts"
        :contacts="contacts"
      />
      <video-card
        v-if="hasVideo"
        :video="video"
      />
    </div>
  </div>
</template>

<script>
import ContactsCard from '@/components/exhibitors/cards/contacts/card.vue';
import ProductsCard from '@/components/exhibitors/cards/products/card.vue';
import VideosCard from '@/components/exhibitors/cards/videos/card.vue';
import VideoCard from '@/components/exhibitors/cards/video/card.vue';
import BannerImage from '@/components/exhibitors/ui/banner.vue';
import LogoImage from '@/components/exhibitors/ui/logo.vue';
import SocialIcons from '@/components/exhibitors/ui/social-icons.vue';
import BoothManageAlert from './manage/alert.vue';
import BoothLiveToggle from './manage/toggle-live.vue';
import { head, isEmpty } from '@/utils/lodash';
import { mapGetters } from 'vuex';
export default {
  components: {
    VideoCard,
    LogoImage,
    VideosCard,
    SocialIcons,
    BannerImage,
    ContactsCard,
    ProductsCard,
    BoothLiveToggle,
    BoothManageAlert
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isBoothManager']),
    ...mapGetters('conferences', ['current']),
    bannerImage() {
      return this.exhibitor.banner;
    },
    logoImage() {
      return this.exhibitor.logo;
    },
    hasBannerImage() {
      return !isEmpty(this.bannerImage);
    },
    hasLogoImage() {
      return !isEmpty(this.logoImage);
    },
    title() {
      return this.exhibitor.title;
    },
    description() {
      return this.exhibitor.description;
    },
    website() {
      return this.exhibitor.website;
    },
    contacts() {
      return this.exhibitor.contacts;
    },
    hasContacts() {
      return !isEmpty(this.contacts);
    },
    products() {
      return this.exhibitor.products;
    },
    hasProducts() {
      return !isEmpty(this.products);
    },
    video() {
      return head(this.videos);
    },
    videos() {
      return this.exhibitor.videos;
    },
    hasVideo() {
      return !isEmpty(this.video);
    },
    hasVideos() {
      return !isEmpty(this.videos);
    },
    canManageBooth() {
      return this.isBoothManager(this.exhibitor);
    }
  }
};
</script>