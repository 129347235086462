<template>
  <div
    class="p-4 flex flex-col justify-center items-center space-y-2"
    @click="onClick"
  >
    <product-image
      v-if="image"
      :image="image"
      class="mb-2"
    />
    <h4 class="text-xl font-medium">
      {{ product.title }}
    </h4>
    <div v-if="product.price>0">
      <h4 class="text-primary text-lg font-medium">
        {{ price }}
      </h4>
    </div>
  </div>
</template>

<script>
import { get, head, find, isEmpty } from '@/utils/lodash';
import { currency } from '@/utils/helpers';
import ProductImage from './image.vue';
export default {
  components: {
    ProductImage
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  emits: ['click'],
  computed: {
    price() {
      return currency(this.product.price);
    },
    images() {
      return get(this.product, 'images', []);
    },
    primary() {
      return find(this.images, image => image.primary);
    },
    first() {
      return head(this.images);
    },
    image() {
      if (!isEmpty(this.primary)) {
        return this.primary.image.path;
      }

      return get(this.first, 'image.path');
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.product);
    }
  }
};
</script>