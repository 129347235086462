<template>
  <a-avatar
    v-bind="$attrs"
    :source="source"
    round="full"
    class="flex-shrink-0"
  >
    <template
      v-if="!hasSource"
      #placeholder
    >
      <slot name="placeholder">
        <span class="text-lg leading-none text-white">{{ initials }}</span>
      </slot>
    </template>
  </a-avatar>
</template>

<script>
import { resize } from '@/utils/resize';
import { get, isEmpty } from '@/utils/lodash';
export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  computed: {
    path() {
      return get(this.contact, 'image.path');
    },
    initials() {
      return this.contact.initials;
    },
    hasSource() {
      return !isEmpty(this.source);
    },
    source() {
      let path = this.path;

      if (!path) {
        return;
      }

      return resize(path, 200, 200);
    }
  }
};
</script>