<template>
  <social-icon
    v-if="facebook"
    icon="facebook"
    :url="facebook"
  />
  <social-icon
    v-if="instagram"
    icon="instagram"
    :url="instagram"
  />
  <social-icon
    v-if="twitter"
    icon="twitter"
    :url="twitter"
  />
  <social-icon
    v-if="youtube"
    icon="youtube"
    :url="youtube"
  />
</template>

<script>
import SocialIcon from '@/components/social/icon.vue';
import { get } from '@/utils/lodash';
export default {
  components: {
    SocialIcon
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    facebook() {
      return get(this.row, 'facebook');
    },
    instagram() {
      return get(this.row, 'instagram');
    },
    twitter() {
      return get(this.row, 'twitter');
    },
    youtube() {
      return get(this.row, 'youtube');
    }
  }
};
</script>