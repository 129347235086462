<template>
  <a-modal
    open
    wide
    @close="onClose"
  >
    <div class="max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div class="lg:col-start-1 lg:col-span-1 sm:border-r border-gray-200">
        <product-images
          v-if="hasImages"
          :images="images"
        />
      </div>
      <div class="lg:col-start-2 lg:col-span-2 space-y-3">
        <h4 class="text-3xl font-medium">
          {{ product.title }}
        </h4>
        <h4
          v-if="product.price>0"
          class="text-primary text-xl font-medium"
        >
          {{ price }}
        </h4>
        <div class="leading-6 text-black text-sm">
          <div v-text="product.description" />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { get, isEmpty } from '@/utils/lodash';
import { currency } from '@/utils/helpers';
import ProductImages from './images.vue';
export default {
  components: {
    ProductImages
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    price() {
      return currency(this.product.price);
    },
    images() {
      return get(this.product, 'images');
    },
    hasImages() {
      return !isEmpty(this.images);
    }
  },
  created() {
    this.$router.push({ name: this.$route.name, hash: `#${this.product.id}` });
  },
  beforeUnmount() {
    this.$router.push({ name: this.$route.name, hash: null });
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>