<template>
  <div>
    <div class="mb-20">
      <img
        v-if="selected"
        :src="selected"
        alt=""
      >
    </div>
    <div class="flex flex-row flex-wrap space-y-1">
      <thumbnail
        v-for="image in images"
        :key="image.id"
        :image="image"
        @click="onThumbnailClicked"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty, find, head, get } from '@/utils/lodash';
import Thumbnail from './thumbnail.vue';
export default {
  components: {
    Thumbnail
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selected: null
  }),
  computed: {
    hasImages() {
      return !isEmpty(this.images);
    },
    primary() {
      return find(this.images, image => image.primary);
    },
    first() {
      return head(this.images);
    },
    firstOrPrimary() {
      if (!isEmpty(this.primary)) {
        return this.primary.image.url;
      }

      return get(this.first, 'image.url');
    }
  },
  watch: {
    images: {
      immediate: true,
      handler: 'onImagesChange'
    }
  },
  methods: {
    onImagesChange() {
      if (this.hasImages) {
        this.selected = this.firstOrPrimary;
      }
    },
    onThumbnailClicked({ image: { url } }) {
      this.selected = url;
    }
  }
};
</script>