<template>
  <default-card
    title="Official Video"
    :padding="false"
  >
    <embed-video :row="video" />
  </default-card>
</template>

<script>
import DefaultCard from '@/components/exhibitors/cards/default.vue';
import EmbedVideo from '@/components/ui/embed/video.vue';
export default {
  components: {
    EmbedVideo,
    DefaultCard
  },
  props: {
    video: {
      type: Object,
      required: true
    }
  }
};
</script>