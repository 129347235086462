<template>
  <img
    :src="source"
    alt=""
    class="h-56"
  >
</template>

<script>
import { resize } from '@/utils/resize';
export default {
  props: {
    image: {
      type: String,
      required: true
    }
  },
  computed: {
    source() {
      return resize(this.image, 1024, 768);
    }
  }
};
</script>