<template>
  <a-alert type="warning">
    <div class="flex flex-col md:flex-row space-y-2 justify-between">
      <div>
        This is the public view of the virtual booth. Click the button to the right to manage it.
      </div>
      <div>
        <router-link
          :to="{name: 'virtual.booth.manage'}"
          class="px-2 py-1 bg-primary hover:bg-primary-active text-white rounded"
        >
          Manage Booth
        </router-link>
      </div>
    </div>
  </a-alert>
</template>

<script>
export default {};
</script>