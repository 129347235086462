<template>
  <default-card
    title="Contacts"
    padding
  >
    <contact-row
      v-for="contact in contacts"
      :key="contact.id"
      :contact="contact"
    />
  </default-card>
</template>

<script>
import DefaultCard from '@/components/exhibitors/cards/default.vue';
import ContactRow from './row.vue';
export default {
  components: {
    ContactRow,
    DefaultCard
  },
  props: {
    contacts: {
      type: Array,
      required: true
    }
  }
};
</script>