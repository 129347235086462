<template>
  <a
    :href="url"
    target="_blank"
  >
    <a-icon
      type="fab"
      :name="icon"
      size="lg"
      v-bind="$attrs"
    />
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  }
};
</script>